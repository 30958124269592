<template>
  <div ref="container" class="newsContainer">
    <div class="title">联系列表</div>
    <div class="tableContainer">
      <div class="addWrap">
        <el-button class="add" @click.stop="allRead" v-if="tableData.length > 0"
          >全部已读</el-button
        >
      </div>

      <el-row>
        <el-col class="table">
          <el-table
            :data="tableData"
            border
            header-cell-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
            @row-click="handleEdit"
          >
            <el-table-column label="ID" width="90" prop="id" align="center" />

            <el-table-column
              label="上传"
              min-width="214"
              prop="createTime"
              align="center"
            />
            <el-table-column
              label="姓名"
              min-width="156 "
              prop="name"
              align="center"
            />
            <el-table-column
              label=" 职位"
              min-width="118 "
              prop="position"
              align="center"
            />
            <el-table-column
              label="联系电话"
              min-width="154"
              prop="mobile"
              align="center"
            />
            <el-table-column label="状态" min-width="142" align="center">
              <template slot-scope="scope">
                <span
                  :style="scope.row.readFlag === 1 ? '' : 'cursor:pointer;'"
                  class="editCommon"
                  @click="handleEdit(scope.row)"
                  >{{ scope.row.readFlag === 1 ? "已读" : "未读" }}</span
                >
              </template>
            </el-table-column>
          </el-table>

          <!-- </div> -->
        </el-col>
      </el-row>
      <div class="pagination" style="text-align: center">
        <el-pagination
          :total="contactsDetail.total"
          :page-size="10"
          layout="prev, pager, next"
          @current-change="clickPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { readContact } from "@/apis/user";
import { parseTime } from "@/utils/index";
export default {
  data() {
    return {
      tableData: [],
      contactsDetail: "",
      current: 1,
    };
  },
  activated() {
    this.getContactList(this.current);
  },
  methods: {
    async getContactList(current = 1) {
      var res = await this.$store.dispatch("getContactList", { current });

      var records = res.contacts.records;
      records.forEach((item) => {
        item.createTime = parseTime(item.createTime);
      });
      this.tableData = records;
      this.contactsDetail = res.contacts;
    },
    handleEdit(row) {
      if (row.readFlag === 0) {
        readContact({ id: row.id }).then(() => {
          row.readFlag = 1;
          this.$message1("该条记录已查阅", "success");
          this.getContactList();
        });
      }
    },
    allRead() {
      // console.log(111)
      if (this.tableData.length == 0) return;
      readContact({ id: -1 }).then(() => {
        this.$message1("全部查阅完毕", "success");
        this.getContactList();
      });
    },
    // 页码切换
    clickPage(e) {
      // //console.log(e)
      this.getContactList(e);
      this.current = e;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";
.newsContainer {
  // height:calc(100vh - 118px);
  // position:relative;
  padding: 40px 0 40px 0;
  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 34px;
  }
  .tableContainer {
    overflow: hidden;
    // width:100%;
    // min-height:440px;
    border: solid 1px #d2d2d2;
    margin: 40px 34px 0;
    box-sizing: border-box;
    .addWrap {
      overflow: hidden;
    }
    .add {
      float: right;
      margin: 12px 22px 0 0;
      @include btnCardConfirm(100px, 34px, 14px);
    }
    .table {
      margin-top: 12px;
    }
  }
  .pagination {
    margin-top: 24px;
  }
}
</style>
